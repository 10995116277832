import React from "react"
import { Link, graphql } from "gatsby"
import { injectIntl } from "gatsby-plugin-intl"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {
  Section,
  SectionTitle,
  SectionDescription,
  MainPanel,
  LeftPannel,
  RightPannel,
  MarkdownContent,
  BreadCrumb,
} from "../components/Section"
import { BulletIcon } from "../components/Icons"
import TopPageHeader from "../components/TopPageHeader"
import ContactCard from "../components/ContactCard"
import ProductForm from "../sections/Product/ProductForm"

function ContentPage({ intl, data }) {
  const pageData = data.contentfulContentPage
  return (
    <Layout>
      <SEO title={pageData.metaTitle} description={pageData.metaDescription} />
      <BreadCrumb>
        <div className="container">
          <Link to="/">
            {intl.formatMessage({ id: "Home" })} <BulletIcon />
          </Link>
          <span>{pageData.pageName}</span>
        </div>
      </BreadCrumb>
      <TopPageHeader topHeaderTitle={pageData.heroTitle} />
      <Section pt="90px" pb="90px" bgColor="#fff">
        <div className="container">
          <MainPanel>
            <LeftPannel>
              <MarkdownContent>
                <div
                  dangerouslySetInnerHTML={{
                    __html: pageData.content.childMarkdownRemark.html,
                  }}
                />
              </MarkdownContent>
            </LeftPannel>
            <RightPannel>
              <ProductForm intl={intl} />
            </RightPannel>
          </MainPanel>
        </div>
      </Section>
      <Section pt="90px" pb="150px" xpt="60px" xpb="90px" bgColor="#F9F9F9">
        <div className="container">
          <SectionTitle>{pageData.ctaSectionTitle}</SectionTitle>
          <SectionDescription maxWidth="670px" mb="50px">
            <div
              dangerouslySetInnerHTML={{
                __html: pageData.ctaSectionDescription.childMarkdownRemark.html,
              }}
            />
          </SectionDescription>

          <ContactCard intl={intl} />
        </div>
      </Section>
    </Layout>
  )
}

export default injectIntl(ContentPage)

export const query = graphql`
  query ContentPageQuery($slug: String, $locale: String) {
    contentfulContentPage(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      node_locale
      metaTitle
      metaDescription
      slug
      pageName
      heroTitle
      content {
        childMarkdownRemark {
          html
        }
      }
      ctaSectionTitle
      ctaSectionDescription {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
